import React from 'react'
import { graphql } from 'gatsby'
import { startCase } from 'lodash'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import Header from '../components/SermonPage/Header'
import BreadCrumb from '../components/SermonPage/BreadCrumb'
import SermonList from '../components/SermonPage/SermonList'
import SermonCard from '../components/SermonPage/SermonCard'
import { SUNDAY_SERVICE_BASE, SERIES_BASE } from '../routes'
import { SUNDAY_SERVICE_TITLE, WEEKDAY_SERVICE_TITLE } from '../constants'

const Sermons = ({ data, pageContext }) => {
    const { basePath, headerTitle } = pageContext
    const title = (basePath === SUNDAY_SERVICE_BASE) ? SUNDAY_SERVICE_TITLE : WEEKDAY_SERVICE_TITLE;
    const sermons = (basePath === SUNDAY_SERVICE_BASE) ? data.allContentfulSermonSunday.edges : data.allContentfulSermonWeekday.edges;
    const routes = [{ slug: basePath, label: title }];
 
    return (
        <Layout>
            <SEO title={startCase(basePath)} />
            <Header title={headerTitle} height={'20vh'} />
            <Container>
                <BreadCrumb routes={routes} />
                <SermonList>
                    {sermons
                      .filter(({ node: sermon }) => (sermon.mediaUrl))
                      .map(({ node: sermon }) => (
                        <SermonCard key={sermon.id} {...sermon} sermonBasePath={basePath} seriesBasePath={SERIES_BASE} routes={routes} />
                    ))}
                </SermonList>
            </Container>
            <Pagination context={pageContext} />
        </Layout>
    )
}

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        allContentfulSermonSunday(
            sort: { fields: [date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    title
                    dateSlug: date(formatString: "YYYY-MM-DD")
                    mediaUrl
                    speaker
                    verse
                    series {
                        ... on ContentfulSeries {
                            name
                            slug
                        }
                    }
                }
            }
        }
        allContentfulSermonWeekday(
            sort: { fields: [date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    title
                    dateSlug: date(formatString: "YYYY-MM-DD")
                    mediaUrl
                    speaker
                    verse
                    series {
                        ... on ContentfulSeries {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`

export default Sermons
